<template>
    <v-slider
    v-model="initValue"
    label="N of batteries"
    min="0" max="9"
    thumb-label="always"
    class="mt-12"
    :prepend-icon="alert ? 'mdi-alert' : undefined"
    :color="alert ? 'red' : undefined"
    :value="value"
    @input="$emit('input', $event)"
    @change="updateValue"/>
</template>

<script>
export default {
    data: () => ({ initValue: undefined }),
    props: ['value', 'alert'],
    created() {
        this.initValue = this.$store.state.batteries.amount;
    },
    methods: {
        updateValue(value) {
            this.$store.commit('setNBatteries', value);
        },
    },
}
</script>