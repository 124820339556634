<template>
    <v-checkbox
    v-model="initValue"
    label="Parallel port"
    :color="alert ? 'red' : undefined"
    :prepend-icon="alert ? 'mdi-alert' : undefined"
    :value="value"
    @change="updateValue"/>
</template>

<script>
export default {
    data: () => ({ initValue: undefined }),
    props: ['value', 'alert'],
    created() {
        this.initValue = this.$store.state.ports.parallel;
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value)
            this.$store.commit('setParallelPort', value);
        },
    },
}
</script>